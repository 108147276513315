import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const LineTextLine = makeShortcode("LineTextLine");
const SimpleReactLightbox = makeShortcode("SimpleReactLightbox");
const LightBox = makeShortcode("LightBox");
const Container = makeShortcode("Container");
const Row = makeShortcode("Row");
const Col = makeShortcode("Col");
const ImageBed = makeShortcode("ImageBed");
const Picture = makeShortcode("Picture");
const Link = makeShortcode("Link");
const AndManyMore = makeShortcode("AndManyMore");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title={props.pageContext.frontmatter.title} description={props.pageContext.frontmatter.description} mdxType="Seo" />
    <LineTextLine title={props.pageContext.frontmatter.title} mdxType="LineTextLine" />
    <SimpleReactLightbox mdxType="SimpleReactLightbox">
  <LightBox mdxType="LightBox">
    <Container mdxType="Container">
      <blockquote>
        <b>Analog photography</b>, images captured and done by good-old
        technique - photographic film or plate.
      </blockquote>
      <Row mdxType="Row">
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Fawn" modelName="Alexander Stepanenko" place="Moscow" filename="fawn-analogue-photography-by-andrey-bond-6.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Fawn" modelName="Aleksander Stepanenko" place="Moscow" filename="fawn-analogue-photography-by-andrey-bond-5.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
      </Row>
      <Row mdxType="Row">
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Fawn" modelName="Aleksander Stepanenko" place="Moscow" filename="fawn002.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Fawn" modelName="Aleksander Stepanenko" place="Moscow" filename="fawn-analogue-photography-by-andrey-bond-3.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Fawn" modelName="Aleksander Stepanenko" place="Moscow" filename="fawn-analogue-photography-by-andrey-bond-8.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Fawn" modelName="Aleksander Stepanenko" place="Moscow" filename="fawn-analogue-photography-by-andrey-bond-11.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
      </Row>
      <blockquote>
        <b>FAWN</b>, NIKON D5, LENS: JUPITER 200MM/4, model:{" "}
        <Link to="/men-s-portraits/alexander" mdxType="Link">Alexander Stepanenko</Link>.
        Moscow
        <br />
        <b>Photographer</b>: Andrey Bond.
      </blockquote>
    </Container>
    <Container mdxType="Container">
      <Row mdxType="Row">
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Simon" modelName="Simon Dmitriev" place="Moscow" filename="analogue-photography-by-andrey-bond-2.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Simon" modelName="Simon Dmitriev" place="Moscow" filename="Simon-analogue-photo-scrached2.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Simon" modelName="Simon Dmitriev" place="Moscow" filename="analogue_photography_by_andrey_bond_3.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
      </Row>
      <blockquote>
        <b>SIMON</b>, FILM3 PROJECT, film ILFORD Delta 400 pro, camera Nikon F5,
        model: <Link to="/men-s-portraits/simon/" mdxType="Link">Simon Dmitriev</Link>. Moscow
        <br />
        <b>Photographer</b>: Andrey Bond.
      </blockquote>
    </Container>
    <Container mdxType="Container">
      <Row mdxType="Row">
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Chrysanthemum" modelName="Chrysanthemum" place="Moscow" filename="Chrysanthemum.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Applecore" modelName="Apple" place="Moscow" filename="Still_life_of_apple cores.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Applecore" modelName="Apple" place="Moscow" filename="Still_life_of_apple cores_002.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
      </Row>
    </Container>
  </LightBox>
    </SimpleReactLightbox>
    <AndManyMore mdxType="AndManyMore" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      